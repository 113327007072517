// ? Titulos y Links del menú en español 
export const navItemsEs = [
    "Inicio",
    "Institución",
    "Personal",
    "Vida escolar",
    "Menú cafetería",
    "Circulares",
    "Fechas importantes",
    "Manual ABC 24 - 25",
    "Manual Teams",
    "Jornalización Parvularia",
    "Jornalización Primaria-Secundaria",
    "Contáctanos"
];

export const navLinksEs = [
    "/", "/institucion",
    "/personal",
    "/vida-escolar",
    "img/menu-cafeteria.jpg",
    "/circulares",
    "/fechas-importantes",
    "/assets/documents/Manual_de_Convivenvia_2024-2025.pdf",
    "/assets/documents/Manual-Teams.pdf",
    "/assets/documents/jornalizacion-parvularia.pdf",
    "/assets/documents/jornalizacion-prepa-12vo.pdf",
    "/contactanos"
];

export const navDropEs = [ "Menú", "Conócenos", "Información" ];


// ? Titulos y Links del menú en ingles 
export const navItemsEn = [
    "Home",
    "Institution",
    "Our team",
    "School life",
    "Cafeteria menu",
    "Newsletter",
    "Important dates",
    "ABC manual 23 - 24",
    "Teams manual",
    "Preschool Scheduling",
    "Primary-High School Scheduling",
    "Contact us"
];

export const navLinksEn = [
    "/home", "/institution",
    "/our-team",
    "/school-life",
    "img/menu-cafeteria.jpg",
    "/newsletter",
    "/important-dates",
    "/assets/documents/MANUAL DE CONVIVENCIA 2023-2024.pdf",
    "/assets/documents/Manual-Teams.pdf",
    "/assets/documents/jornalizacion-parvularia.pdf",
    "/assets/documents/jornalizacion-prepa-12vo.pdf",
    "/contact-us"
];

export const navDropEn = [ "Menu","About us","Information" ];


// ? Menú de cafetería 
export const cafeteria = [
    "Semana del 27 al 31 de enero de 2025.",

    // ******* LUNES *********** 
    "Carne a la plancha con ensalada de papas.",

    // ******* MARTES ********** 
    "Pollo guisado, arroz blanco y ensalada fresca.",

    // ******* MIERCOLES ******* 
    "Club Sándwich con nachos.",

    // ******* JUEVES ********** 
    "Dobladitas de pollo con chirimol.",
    
    // ******* VIERNES ********* 
    "Hot dog con papas",

    // *--------------------------- 
    "Menú del 27 al 31 de enero.",
    "Menu from January 27 to 31",
    "January 25, 2025"
];

export let equipos = {
    admin: {
        title: ["Personal Administrativo", "Administrative Staff"],
        eq: [
            {"nombre":"Mrs. Verónica Jordan Moore", "cargo":"PRESIDENTA JUNTA DIRECTIVA","position":"PRESIDENT BOARD OF DIRECTORS","link":"/assets/img/equipo/admin/01_Veronica_Jordan_Moore.webp"},
            {"nombre":"Mrs. Valeria Walsh de Arias", "cargo":"DIRECTORA GENERAL","position":"PRINCIPAL","link":"/assets/img/equipo/admin/02_Valeria_Walsh_de_Arias.webp"},
            {"nombre":"Mrs. Margarita S. de Maldonado", "cargo":"DIRECTORA ACADÉMICA","position":"ACADEMIC DIRECTOR","link":"/assets/img/equipo/admin/03_Margarita_de_Maldonado.webp"},
            {"nombre":"Mrs. Violeta de Portillo", "cargo":"COORDINADORA GENERAL","position":"GENERAL COORDINATOR","link":"/assets/img/equipo/admin/04_Violeta_de_Portillo.webp"},
            {"nombre":"Mr. Carlos Zavaleta", "cargo":"CONTADOR","position":"ACCOUNTANT","link":"/assets/img/equipo/admin/05a_Carlos_Zavaleta.webp"},
            {"nombre":"Mr. Ulises Ivan Monterroza", "cargo":"CONTADOR","position":"ACCOUNTANT","link":"/assets/img/equipo/admin/05b_Ulises_Ivan_Monterroza.webp"},
            {"nombre":"Ms. Karla Guerrero", "cargo":"ASISTENTE ADMINISTRATIVA","position":"ADMINISTRATIVE ASSISTANT","link":"/assets/img/equipo/admin/06_Karla_Guerrero.webp"},
            {"nombre":"Ms. Gabriela Toledo d Hernández", "cargo":"CONSEJERA ESTUDIANTIL","position":"STUDENT COUNSELOR","link":"/assets/img/equipo/admin/07_Gabriela_Toledo.webp"},
            {"nombre":"Mrs. Karla Rodriguez", "cargo":"COORDINADORA DE INGLES","position":"ENGLISH COORDINATOR","link":"/assets/img/equipo/admin/08_Karla_Rodriguez.webp"}
        ]
    },
    parvu: {
        title: ["Personal de Parvularia", "Preschool Staff"],
        eq: [
            {"nombre":"Ms. Maira Gutiérrez", "cargo":"PREMATERNAL","position":"NURSERY","link":"/assets/img/equipo/parvularia/01_Maira_Gutierrez.webp"},
            {"nombre":"Ms. Michelle Ayala", "cargo":"MATERNAL A","position":"EARLY CHILDHOOD A","link":"/assets/img/equipo/parvularia/02a_Michelle_Ayala.webp"},
            {"nombre":"Ms. Paola Melania López Wyler", "cargo":"MATERNAL B","position":"EARLY CHILDHOOD B","link":"/assets/img/equipo/parvularia/02b_Paola_Lopez.webp"},
            // {"nombre":"Ms. Jeanette Jacobo", "cargo":"PRE KINDER A","position":"PREKINDER A","link":"/assets/img/equipo/parvularia/03a_Jeanette_Jacobo.webp"},
            {"nombre":"Ms. Paola Aguilar", "cargo":"PRE KINDER A","position":"PREKINDER A","link":"/assets/img/equipo/parvularia/03a_Paola_Aguilar.webp"},
            {"nombre":"Ms. Eugenia González", "cargo":"PRE KINDER B","position":"PREKINDER B","link":"/assets/img/equipo/parvularia/03b_Eugenia_Gonzalez.webp"},
            // {"nombre":"Ms. Paola Aguilar", "cargo":"KINDER A","position":"KINDER A","link":"/assets/img/equipo/parvularia/04a_Paola_Aguilar.webp"},
            {"nombre":"Mrs. Erika Salinas", "cargo":"KINDER A","position":"KINDER A","link":"/assets/img/equipo/parvularia/04a_Erika_Salinas.webp"},
            {"nombre":"Ms. Gabriela Araujo", "cargo":"KINDER B","position":"KINDER B","link":"/assets/img/equipo/parvularia/04b_Gabriela_Araujo.webp"},
            {"nombre":"Ms. Ingrid Aguilera", "cargo":"PREPARATORIA A","position":"PREPARATORY A","link":"/assets/img/equipo/parvularia/05a_Ingrid_Aguilera.webp"},
            {"nombre":"Ms. Josselyn López", "cargo":"PREPARATORIA B","position":"PREPARATORY B","link":"/assets/img/equipo/parvularia/05b_Josselyn_Lopez.webp"},
            // {"nombre":"Ms. Cecilia González", "cargo":"PREPARATORIA B","position":"PREPARATORY B","link":"/assets/img/equipo/parvularia/05b_Cecilia_Gonzalez.webp"},
            {"nombre":"Ms. María Fernanda Martínez", "cargo":"ASISTENTE PRE KINDER A","position":"PREKINDER A ASSISTANT","link":"/assets/img/equipo/parvularia/06a_Maria_Fernanda_Martinez.webp"},
            {"nombre":"Ms. Camila Escolán", "cargo":"ASISTENTE PRE KINDER B","position":"PREKINDER B ASSISTANT","link":"/assets/img/equipo/parvularia/06b_Camila_Escolan.webp"},
            {"nombre":"Ms. Karla Aguilar", "cargo":"ASISTENTE DE KINDER A","position":"KINDER A ASSISTANT","link":"/assets/img/equipo/parvularia/07_Karla_Aguilar.webp"},
            {"nombre":"Ms. Gaby Hernandez", "cargo":"ASISTENTE DE PREPARATORIA A","position":"PREPARATORY A ASSISTANT","link":"/assets/img/equipo/parvularia/08a_Gaby_Hernandez.webp"},
            {"nombre":"Ms. Beatríz Lizama", "cargo":"ASISTENTE DE PREPARATORIA B","position":"PREPARATORY B ASSISTANT","link":"/assets/img/equipo/parvularia/08b_Beatriz_Lizama.webp"},
            {"nombre":"Mr. Ulises Ramírez", "cargo":"DEPORTE","position":"PHYSICAL EDUCATION","link":"/assets/img/equipo/parvularia/09_Ulises_Ramirez.webp"}
        ]
    },
    prima: {
        title: ["Personal de Primaria", "Primary School Staff"],
        eq: [
            {"nombre":"Ms. Sally Ventura", "cargo":"1º GRADO A","position":"1st GRADE A","link":"/assets/img/equipo/primaria/01a_Sally_Ventura.webp"},
            // {"nombre":"Mrs. Alejandra Argueta", "cargo":"1º GRADO B","position":"1st GRADE B","link":"/assets/img/equipo/primaria/01b_Alejandra_de_Salgado.webp"},
            {"nombre":"Ms. Elizabeth Jacobo", "cargo":"1º GRADO B","position":"1st GRADE B","link":"/assets/img/equipo/primaria/01b_Jeanette_Jacobo.webp"},
            // {"nombre":"Mrs. Tatiana de Portillo", "cargo":"2º GRADO A","position":"2nd GRADE A","link":"/assets/img/equipo/primaria/02a_Tatiana_de_Portillo.webp"},
            {"nombre":"Ms. Xenia Leiva", "cargo":"2º GRADO A","position":"2nd GRADE A","link":"/assets/img/equipo/primaria/02a_Xenia_Leiva.webp"},
            {"nombre":"Mrs. Celia Olmedo de Cano", "cargo":"2º GRADO B","position":"2nd GRADE B","link":"/assets/img/equipo/primaria/02b_Celia_Olmedo_de_Cano.webp"},
            // *{"nombre":"Ms. Lissa Nolasco", "cargo":"2º GRADO B","position":"2nd GRADE B","link":"/assets/img/equipo/primaria/02b_Lissa_Nolasco.webp"},
            // {"nombre":"Ms. Ingrid Aguilera", "cargo":"3º GRADO A","position":"3rd GRADE A","link":"/assets/img/equipo/primaria/03a_Ingrid_Aguilera.webp"},
            {"nombre":"Ms. Camila Dimas", "cargo":"3º GRADO A","position":"3rd GRADE A","link":"/assets/img/equipo/primaria/03a_Camila_Dimas.webp"},
            // {"nombre":"Mrs. María Begoña González", "cargo":"3º GRADO B","position":"3rd GRADE B","link":"/assets/img/equipo/primaria/03b_Maria_Begona_Gonzalez.webp"},
            {"nombre":"Ms. Roxana Peñate", "cargo":"3º GRADO B","position":"3rd GRADE B","link":"/assets/img/equipo/primaria/03b_Roxana_Penate.webp"},
            {"nombre":"Mrs. María Eugenia de Calderón", "cargo":"4º GRADO A","position":"4th GRADE A","link":"/assets/img/equipo/primaria/04a_Maria_Eugenia_de_Calderon.jpg"},
            {"nombre":"Ms. Tatiana de Portillo", "cargo":"4º GRADO B","position":"4th GRADE B","link":"/assets/img/equipo/primaria/04b_Tatiana_de_Portillo.jpg"},
            {"nombre":"Ms. Jessica Burgos", "cargo":"5º GRADO A","position":"5th GRADE A","link":"/assets/img/equipo/primaria/05a_Jessica_Burgos.webp"},
            {"nombre":"Mrs. María Begoña González", "cargo":"5º GRADO B","position":"5th GRADE B","link":"/assets/img/equipo/primaria/05b_Maria_Begona_Gonzalez.webp"},
            {"nombre":"Mr. Carlos Revolorio", "cargo":"MÚSICA","position":"MUSIC","link":"/assets/img/equipo/secundaria/05_Carlos_Revolorio.webp"},
            {"nombre":"Mrs. Marielos de Walsh", "cargo":"ARTE","position":"ART","link":"/assets/img/equipo/primaria/06_Marielos_de_Walsh.webp"},
            {"nombre":"Mr. Jorge López", "cargo":"MAESTRO DE COMPUTACIÓN","position":"COMPUTER TEACHER","link":"/assets/img/equipo/primaria/07_Jorge_Lopez.webp"},
            {"nombre":"Mr. Eduardo Solórzano", "cargo":"DEPORTE","position":"PHYSICAL EDUCATION","link":"/assets/img/equipo/primaria/08_Eduardo_Solorzano.jpg"}
        ]
    },
    secun: {
        title: ["Personal de Secundaria", "High School Staff"],
        eq: [
            // {"nombre":"Mr. Carlos Revolorio", "cargo":"6º GRADO","position":"6th GRADE","link":"/assets/img/equipo/secundaria/01_Carlos_Revolorio.webp"},
            {"nombre":"Ms. Stephanie Rodriguez", "cargo":"6º GRADO","position":"6th GRADE","link":"/assets/img/equipo/secundaria/01_Stephanie_Rodriguez.webp"},
            // {"nombre":"Mrs. Ana Regina Miranda", "cargo":"7º GRADO","position":"7th GRADE","link":"/assets/img/equipo/secundaria/02_Ana_Regina_Miranda.webp"},
            {"nombre":"Mr. David Bayona", "cargo":"7º GRADO","position":"7th GRADE","link":"/assets/img/equipo/secundaria/02_David_Bayona.webp"},
            {"nombre":"Mr. Guillermo Torres", "cargo":"8º GRADO","position":"8th GRADE","link":"/assets/img/equipo/secundaria/03_Guillermo_Torres.webp"},
            {"nombre":"Mrs. Ana Regina Miranda", "cargo":"9º GRADO","position":"9th GRADE","link":"/assets/img/equipo/secundaria/04_Ana_Regina_Miranda.webp"},
            {"nombre":"Mr. Carlos Revolorio", "cargo":"10º GRADO","position":"10th GRADE","link":"/assets/img/equipo/secundaria/05_Carlos_Revolorio.webp"},
            {"nombre":"Mrs. Galia Merino", "cargo":"11º GRADO","position":"11th GRADE","link":"/assets/img/equipo/secundaria/06_Galia_Merino.webp"},
            {"nombre":"Mrs. Any Vigil de Barrera", "cargo":"12º GRADO","position":"12th GRADE","link":"/assets/img/equipo/secundaria/07_Ana_Gertrudis_de_Barrera.webp"},
            {"nombre":"Ms. Jacqueline Coreas", "cargo":"MAESTRA DE FRANCES","position":"FRENCH TEACHER","link":"/assets/img/equipo/secundaria/08_Jacqueline_Coreas.webp"},
            {"nombre":"Mr. Eduardo Santos", "cargo":"MAESTRO DE FRANCES","position":"FRENCH TEACHER","link":"/assets/img/equipo/secundaria/09_Efrain_Arevalo.webp"},
            {"nombre":"Mrs. Marielos de Walsh", "cargo":"ARTE","position":"ART","link":"/assets/img/equipo/secundaria/10_Marielos_de_Walsh.webp"},
            {"nombre":"Mr. Jorge López", "cargo":"MAESTRO DE COMPUTACIÓN","position":"COMPUTER TEACHER","link":"/assets/img/equipo/primaria/07_Jorge_Lopez.webp"},
            {"nombre":"Mr. Eduardo Solórzano", "cargo":"DEPORTE","position":"PHYSICAL EDUCATION","link":"/assets/img/equipo/primaria/08_Eduardo_Solorzano.jpg"}
        ]
    },
    nivel: {
        title: ["Aula de Nivelación", "Development Center"],
        eq: [
            {"nombre":"Ms. Estefany Quadra", "cargo":"PARVULARIA","position":"PRESCHOOL","link":"/assets/img/equipo/nivelacion/01_Estefany_Quadra.webp"},
            {"nombre":"Mrs. Alejandra Argueta", "cargo":"CICLO 1A","position":"LAVEL 1A","link":"/assets/img/equipo/nivelacion/01a_Alejandra_de_Salgado.webp"},
            {"nombre":"Ms. Camila Ibarra", "cargo":"CICLO 1B","position":"LAVEL 1B","link":"/assets/img/equipo/nivelacion/01b_Camila_Ibarra.webp"},
            {"nombre":"Ms. Elba Maria Bermudez", "cargo":"CICLO 2A","position":"LAVEL 2A","link":"/assets/img/equipo/nivelacion/02a_Elba_Maria_Bermudez.webp"},
            {"nombre":"Ms. Ariana Leonor Parrillas", "cargo":"CICLO 2B","position":"LAVEL 2B","link":"/assets/img/equipo/nivelacion/02b_Ariana_Leonor_Parrillas.webp"},
            {"nombre":"Ms. Kathya Solano", "cargo":"CICLO 3A","position":"LAVEL 3A","link":"/assets/img/equipo/nivelacion/03a_Kathya_Solano.webp"},
            {"nombre":"Mrs. Zuleima Navarro de Vigil", "cargo":"CICLO 3B","position":"LAVEL 3B","link":"/assets/img/equipo/nivelacion/03b_Zuleima_Navarro_de_Vigil.webp"},
        ]
    }
}